import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
  visuallyHidden,
  standardTransition,
} from '../styles';
import ColourSwatches from './ColourSwatches';
import Price from './Price';
import { getDescriptiveName, checkIfMural, buildUrl } from '../utils';
import StarReview from './StarReview';

const StyledProductCard = styled.article``;

const StyledImageWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

const StyledImage = styled(GatsbyImage)`
  ${({ isHover }) => {
    if (isHover) {
      return css`
        ${maxBreakpointQuery.mlarge`
          ${visuallyHidden()};
        `}

        ${minBreakpointQuery.mlarge`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0;
          transition: ${standardTransition('opacity')};

          &:hover {
            opacity: 1;
          }
        `}
      `;
    }
  }}
`;

const StyledProductPillLabel = styled.span`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background-color: ${standardColours.white};
  width: 85px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 50px;
`;

const StyledColourSwatches = styled(ColourSwatches)`
  position: absolute;
  right: 80px;
  bottom: 0;
  left: 16px;
`;

const StyledContent = styled.div`
  ${({ isFeaturedProducts }) => {
    if (isFeaturedProducts) {
      return css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `;
    }
  }}
`;

const StyledHeadingContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ isFeaturedProducts }) => {
    if (!isFeaturedProducts) {
      return css`
        margin: 10px 0;
      `;
    }
  }}

  ${({ isProductPage }) => {
    if (isProductPage) {
      return css`
        ${maxBreakpointQuery.tsmall`
          flex-direction: column;
          align-items: start;
        `}
      `;
    }
  }}
`;

const StyledHeading = styled.h3`
  font-weight: ${fontWeights.semibold};
`;

const StyledText = styled.p`
  color: ${standardColours.darkerGrey};
  ${fontSize(14)};
  line-height: 1.7;
`;

const StyledPrice = styled(Price)`
  ${fontSize(14)};

  ${({ isFeaturedProducts }) => {
    if (isFeaturedProducts) {
      return css`
        margin-left: 10px;
      `;
    } else {
      return css`
        margin-top: 16px;
      `;
    }
  }}
`;

const ProductCard = ({
  id,
  title,
  page,
  featuredImage,
  cardImages,
  description,
  metafields,
  variants: unfilteredVariants,
  category,
  categoryOverrides,
  reviews,
  locale,
  listingDesignView,
  isFeaturedProducts,
  isProductPage,
  ...props
}) => {
  const categoryOverride =
    categoryOverrides &&
    categoryOverrides.length > 0 &&
    categoryOverrides.find(
      ({ productCategory: { shopifyCollection } }) =>
        shopifyCollection === category
    );

  const urlPath = `${buildUrl(page.slug, page)}${
    categoryOverride && categoryOverride.variant
      ? `?variant=${categoryOverride.variant}`
      : ''
  }`;

  const imageOne =
    (categoryOverride && categoryOverride.image) ||
    (cardImages && cardImages.featuredProductCardImageOne
      ? cardImages.featuredProductCardImageOne
      : cardImages.cardImageOne) ||
    featuredImage;
  const imageTwo =
    !categoryOverride && cardImages && cardImages.featuredProductCardImageTwo
      ? cardImages.featuredProductCardImageTwo
      : categoryOverride
      ? categoryOverride.hoverImage !== null
        ? categoryOverride.hoverImage
        : undefined
      : cardImages.cardImageTwo;

  const variants = unfilteredVariants.filter(({ selectedOptions }) =>
    selectedOptions.find(
      ({ name, value }) => name === 'Product Type' && value === 'Wallpaper'
    )
  );

  const isMural = checkIfMural(variants[0].sku);

  const descriptiveName = getDescriptiveName(metafields);

  return (
    <StyledProductCard {...props}>
      <StyledImageWrapper>
        <Link to={urlPath}>
          {(!listingDesignView || !imageTwo) && (
            <StyledImage
              image={imageOne.gatsbyImageData}
              alt={imageOne.alt || title}
            />
          )}
          {imageTwo && (
            <StyledImage
              image={imageTwo.gatsbyImageData}
              alt={imageTwo.alt || title}
              isHover={!listingDesignView}
            />
          )}
        </Link>
        <StyledProductPillLabel>
          {isMural ? 'Mural' : 'Pattern'}
        </StyledProductPillLabel>
        <StyledColourSwatches variants={variants} />
      </StyledImageWrapper>
      <StyledContent isFeaturedProducts={isFeaturedProducts}>
        <StyledHeadingContent
          isProductPage={isProductPage}
          isFeaturedProducts={isFeaturedProducts}
        >
          <StyledHeading>
            <Link to={urlPath}>{title}</Link>
          </StyledHeading>
          {reviews && reviews.length > 0 && (
            <StarReview
              value={
                reviews.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.score,
                  0
                ) / reviews.length
              }
              total={reviews.length}
              isProductCard={true}
            />
          )}
        </StyledHeadingContent>
        {!isFeaturedProducts && descriptiveName && (
          <StyledText>{descriptiveName}</StyledText>
        )}
        <StyledPrice
          variant={variants[0]}
          locale={locale}
          isFeaturedProducts={isFeaturedProducts}
        />
      </StyledContent>
    </StyledProductCard>
  );
};

export default ProductCard;

export const ProductCardFragments = graphql`
  fragment DatoCmsProductCardFragment on DatoCmsProduct {
    shopifyProduct
    cardImageOne: productCardImageOne {
      gatsbyImageData(width: 490, height: 600)
      alt
    }
    cardImageTwo: productCardImageTwo {
      gatsbyImageData(width: 490, height: 600)
      alt
    }
    reviews {
      score
    }
    productCategoryOverrides {
      productCategory {
        shopifyCollection
      }
      image {
        gatsbyImageData(width: 490, height: 600)
        alt
      }
      hoverImage {
        gatsbyImageData(width: 490, height: 600)
        alt
      }
      variant
    }
    ...LinkFragment
  }

  fragment ShopifyProductCardFragment on ShopifyProduct {
    id
    title
    handle
    featuredImage {
      gatsbyImageData(width: 490, height: 600)
      alt: altText
    }
    description
    metafields {
      key
      value
    }
    variants {
      id
      title
      storefrontId
      sku
      presentmentPrices {
        price {
          amount
          currencyCode
        }
      }
      selectedOptions {
        name
        value
      }
      metafields {
        id
        key
        value
      }
      product {
        storefrontId
        handle
      }
    }
    translations {
      fr {
        title
        metafields {
          key
          value
        }
      }
    }
  }
`;
